// 訂閱電子報
'use client';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLoginModal } from '@/slices/popSlice';
import { userState } from '@/slices/userSlice';
import { originURL } from '@/slices/originURLSlice';

const Subscribe = ({ type = null }) => {
    const { user } = useSelector(userState);
    const { cmOrigin } = useSelector(originURL);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div
            className={`text-center ${
                type === 'mobile'
                    ? ''
                    : 'border-t-1 border-dashed border-black2 pt-[17px] pb-[10px]'
            }`}
        >
            <p className="text-bd2b text-black ">
                {t('SELECTED')}
                <span className="text-pale">{t('BLOCKCHAIN')}</span>
                {t('MARKET')}
            </p>
            <p className="text-bd4b text-black9 9">{t('NEWSLETTER')}</p>

            {/* 暫時先開會員系統電子報 */}
            <button
                onClick={() => window?.open('/crypto-read?active=subscribe')}
                className="text-btn1 mt-[16px] border-1 border-black3 px-[40px] py-[10px] text-center text-black trans
                  rounded-[40px] hover:bg-pale hover:!border-pale hover:text-white"
            >
                {t('SUBSCRIBE')}
            </button>
        </div>
    );
};
export default Subscribe;
