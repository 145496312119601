"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import { FOOTER_PAGE_LINK } from "@/common/constant";
import { useTranslation } from "react-i18next";

const FooterLink = () => {
  const { t } = useTranslation();

  return (
    <div
      className="between-center px-[24px] pb-[33px] mb-[33px] border-b-[1px] border-black2 
      uxl:px-[0] ulg:flex-col ulg:gap-y-[30px]"
    >
      <div className="text-center text-bd1n space-y-[30px] blg:space-y-[22px] bmd:space-y-[22px]">
        {/* logo */}
        <Link
          href="/"
          className="img-box block w-[230px] aspect-[244/55] after:content-none [&>img]:trans group"
          prefetch={false}
        >
          <Image
            className="filter-black group-hover:filter-none dark:hidden"
            loading="lazy"
            width={245}
            height={55}
            src="/images/layout/logo.svg"
            alt="footer_logo"
          />

          <Image
            className="hidden dark:block dark:filter-white dark:group-hover:filter-none"
            loading="lazy"
            width={245}
            height={55}
            src="/images/layout/logo_dark.svg"
            alt="footer_logo"
          />
        </Link>
      </div>

      {/* 頁面連結 */}
      <div
        className="flex ml-[70px] flex-grow gap-x-[4.7%] ulg:ml-0 ulg:w-full blg:px-[55px] umd:px-0 [&>div]:between-center [&>div]:flex-grow
      ulg:gap-x-[4.4%] bmd:gap-x-[4.1%] usm:!gap-x-0 usm:flex-col usm:!px-[12px] usm:[&>div]:justify-around usm:gap-y-[40px] usm:my-[20px]"
      >
        <div>
          {FOOTER_PAGE_LINK.slice(0, 3).map((l, i) => (
            // 站內連結、站外連結
            <React.Fragment key={l.title + i}>
              {l?.link ? (
                <>
                  <Link
                    prefetch={false}
                    href={l.link}
                    target={l?.blank ? "_blank" : ""}
                    rel="noreferrer noopener"
                    className="trans text-bd4b text-black9 hover:text-black7"
                  >
                    <span>{t(`${l.title}`)}</span>
                  </Link>
                  {i < FOOTER_PAGE_LINK.length - 1 && (
                    <span className="h-[13px] border-l-1 border-black2" />
                  )}
                </>
              ) : (
                // 開信箱
                <>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="mailto:cs@cryptocity.tw"
                    className="trans text-bd4b text-black9 hover:text-black7"
                  >
                    <span>{t(`${l.title}`)}</span>
                  </a>
                  <span className="h-[13px] border-l-1 border-black2 usm:hidden" />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        <div>
          {FOOTER_PAGE_LINK.slice(3, 6).map((l, i) => (
            // 站內連結、站外連結
            <React.Fragment key={l.title + i}>
              {l?.link ? (
                <>
                  <Link
                    href={l.link}
                    target={l?.blank ? "_blank" : ""}
                    rel="noreferrer noopener"
                    className="trans text-bd4b text-black9 hover:text-black7"
                    prefetch={false}
                  >
                    <span>{t(`${l.title}`)}</span>
                  </Link>
                  {i < 2 && (
                    <span className="h-[13px] border-l-1 border-black2" />
                  )}
                </>
              ) : (
                // 開信箱
                <>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="mailto:busniess@cryptocity.tw"
                    className="trans text-bd4b text-black9 hover:text-black7"
                  >
                    <span>{t(`${l.title}`)}</span>
                  </a>
                  <span className="h-[13px] border-l-1 border-black2" />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterLink;
